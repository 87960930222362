import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { RANDOM_12_LISTINGS, searchListings } from '../SearchPage/SearchPage.duck';
export const ASSET_NAME = 'landing-page';

export const loadData = (params, search, config) => (dispatch, getState, sdk)=> {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  dispatch(fetchPageAssets(pageAsset, true));

  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const searchListingsCall = searchListings(
    {
      ...rest,
      ...originMaybe,
      page,
      perPage: 100,
      include: ['author', 'images'],
      'fields.listing': [
        'title',
        'geolocation',
        'price',
        'publicData.listingType',
        'publicData.transactionProcessAlias',
        'publicData.unitType',
        'publicData.dayPriceTiers',
        // These help rendering of 'purchase' listings,
        // when transitioning from search page to listing page
        'publicData.pickupEnabled',
        'publicData.shippingEnabled',
        'publicData.endBuffer',
        'publicData.startBuffer',
        'availabilityPlan',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },
    config
  );
  return dispatch(searchListingsCall);  
};

function getRandomElements(arr, num) {
  return arr
    .map((a) => ({ sort: Math.random(), value: a }))  // Assign a random number to each element
    .sort((a, b) => a.sort - b.sort)                  // Sort the array by random numbers
    .slice(0, num)                                   // Get the first 'num' elements
    .map((a) => a.value);                             // Return the original elements
}

export const setRandomListings = (listings) => ({
  type: RANDOM_12_LISTINGS,
  payload: getRandomElements(listings, 12),
});

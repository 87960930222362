import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  parseDateFromISO8601,
  stringifyDateToISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { constructQueryParamName, isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { getBufferStartEnd, getSufficientSeats, getAvailabilityForPeriod } from '../../util/data';
import { getDateRanges as getDateRanges2 } from '../../util/dates';

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 84;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const RANDOM_12_LISTINGS = 'app/SearchPage/RANDOM_12_LISTINGS';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  random12Listings: []
};

export const resultIds = data => data.data.map(l => l.id);

export const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    case RANDOM_12_LISTINGS:
      return {
        ...state,
        random12Listings: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// Function for retrieving multiple API-compliant
// date ranges if search is permitted over 90 days
export const getDateRanges = (dates) => {
  if (!dates) {
    return dates;
  }

  // The date search param does some time zone handling,
  // so we'll set the value to a bit less than the hard limit of 90 days
  const apiDayCount = 88;

  const [startDate, endDate] = dates.split(',');
  const dayCount = daysBetween(startDate, endDate);

  if (dayCount <= apiDayCount) {
    return [dates]
  }

  const dateRanges = [];

  const getRange = (start, end) => {
    const rangeStart = typeof start === 'object' ? stringifyDateToISO8601(start) : start;
    const rangeEnd = typeof end === 'object' ? stringifyDateToISO8601(end) : end;
    return `${rangeStart},${rangeEnd}`
  }

  const getAPIRange = (start) => {
    const newRangeEndDate = addTime(start, apiDayCount, 'days');
    // check if the range reaches the actual end time
    if (daysBetween(start, newRangeEndDate) > daysBetween(start, endDate)) {
      dateRanges.push(getRange(start, endDate))
      // if not, get the next API range
    } else {
      dateRanges.push(getRange(start, newRangeEndDate))
      getAPIRange(newRangeEndDate);
    }
  }

  getAPIRange(startDate);

  return dateRanges
}


export const searchListings = (searchParams, config) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  // SearchPage can enforce listing query to only those listings with valid listingType
  // NOTE: this only works if you have set 'enum' type search schema to listing's public data fields
  //       - listingType
  //       Same setup could be expanded to 2 other extended data fields:
  //       - transactionProcessAlias
  //       - unitType
  //       ...and then turned enforceValidListingType config to true in configListing.js
  // Read More:
  // https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
  const searchValidListingTypes = listingTypes => {
    return config.listing.enforceValidListingType
      ? {
        pub_listingType: listingTypes.map(l => l.listingType),
        // pub_transactionProcessAlias: listingTypes.map(l => l.transactionType.alias),
        // pub_unitType: listingTypes.map(l => l.transactionType.unitType),
      }
      : {};
  };

  const omitInvalidCategoryParams = params => {
    const categoryConfig = config.search.defaultFilters?.find(f => f.schemaType === 'category');
    const categories = config.categoryConfiguration.categories;
    const { key: prefix, scope } = categoryConfig || {};
    const categoryParamPrefix = constructQueryParamName(prefix, scope);

    const validURLParamForCategoryData = (prefix, categories, level, params) => {
      const levelKey = `${categoryParamPrefix}${level}`;
      const levelValue = params?.[levelKey];
      const foundCategory = categories.find(cat => cat.id === levelValue);
      const subcategories = foundCategory?.subcategories || [];
      return foundCategory && subcategories.length > 0
        ? {
          [levelKey]: levelValue,
          ...validURLParamForCategoryData(prefix, subcategories, level + 1, params),
        }
        : foundCategory
          ? { [levelKey]: levelValue }
          : {};
    };

    const categoryKeys = validURLParamForCategoryData(prefix, categories, 1, params);
    const nonCategoryKeys = Object.entries(params).reduce(
      (picked, [k, v]) => (k.startsWith(categoryParamPrefix) ? picked : { ...picked, [k]: v }),
      {}
    );

    return { ...nonCategoryKeys, ...categoryKeys };
  };

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  const datesSearchParams = datesParam => {
    const searchTZ = 'Etc/UTC';
    const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';

    // SearchPage need to use a single time zone but listings can have different time zones
    // We need to expand/prolong the time window (start & end) to cover other time zones too.
    //
    // NOTE: you might want to consider changing UI so that
    //   1) location is always asked first before date range
    //   2) use some 3rd party service to convert location to time zone (IANA tz name)
    //   3) Make exact dates filtering against that specific time zone
    //   This setup would be better for dates filter,
    //   but it enforces a UX where location is always asked first and therefore configurability
    const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
    const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

    const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
          ? getExclusiveEndDate(endRaw, searchTZ)
          : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
    const day = 1440;
    const hour = 60;
    // When entire range is required to be available, we count minutes of included date range,
    // but there's a need to subtract one hour due to possibility of daylight saving time.
    // If partial range is needed, then we just make sure that the shortest time unit supported
    // is available within the range.
    // You might want to customize this to match with your time units (e.g. day: 1440 - 60)
    const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    return hasValidDates
      ? {
        start: getProlongedStart(startDate),
        end: getProlongedEnd(endDate),
        // Availability can be time-full or time-partial.
        // However, due to prolonged time window, we need to use time-partial.
        availability: 'time-partial',
        // minDuration uses minutes
        minDuration,
      }
      : {};
  };

  const stockFilters = datesMaybe => {
    const hasDatesFilterInUse = Object.keys(datesMaybe).length > 0;

    // If dates filter is not in use,
    //   1) Add minStock filter with default value (1)
    //   2) Add relaxed stockMode: "match-undefined"
    // The latter is used to filter out all the listings that explicitly are out of stock,
    // but keeps bookable and inquiry listings.
    return hasDatesFilterInUse ? {} : { minStock: 1, stockMode: 'match-undefined' };
  };

  const { perPage, price, dates, sort, mapSearch, ...restOfParams } = searchParams;

  const priceMaybe = priceSearchParams(price);

  let datesMaybe;
  let datesArrayMaybe;
  const dateRanges = getDateRanges(dates);
  if (dateRanges?.length > 1) {
    datesArrayMaybe = dateRanges.map(dr => datesSearchParams(dr));
    datesMaybe = datesArrayMaybe[0];
  } else {
    datesMaybe = datesSearchParams(dates);
  }

  //const datesMaybe = datesSearchParams(dates);
  const stockMaybe = stockFilters(datesMaybe);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  // Update 'params' to a function that takes a datesMaybe attribute
  const params = datesMaybe => {
    return {
      // The rest of the params except invalid nested category-related params
      // Note: invalid independent search params are still passed through
      ...omitInvalidCategoryParams(restOfParams),
      ...priceMaybe,
      ...datesMaybe,
      ...stockMaybe,
      ...sortMaybe,
      ...searchValidListingTypes(config.listing.listingTypes),
      perPage,
    }
  };

  /* return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    }); */

  // Set SDK query to an array of promises with either one or multiple
  // SDK calls, depending on whether datesArrayMaybe has a value
  const listingsPromiseArray = datesArrayMaybe
    ? datesArrayMaybe.map(datesParam => sdk.listings.query(params(datesParam)))
    : [sdk.listings.query(params(datesMaybe))]

  return Promise.all(listingsPromiseArray)
    .then(responses => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      // We're getting an array of responses either way, so we can
      // map that array to dispatch the data handling function.
      responses.map(response => dispatch(addMarketplaceEntities(response, sanitizeConfig)));

      const mapWithIds = (entity => {
        return {
          [entity.id.uuid]: {
            ...entity,
          }
        }
      })

      // TODO this could be more elegant, but it works as a first implementation
      const fullData = responses.reduce((fullResp, item) => {
        // Remove duplicates from across responses by mapping listings with their ids as keys
        return [
          ...(fullResp?.data?.data || []),
          ...item.data.data.map(mapWithIds)
        ]
      }, []).map((item) => {
        // Get an array of unique listings
        const key = Object.keys(item)[0];
        return item[key];
      });

      if (dates) {

        const range = dates.split(",");

        const start = new Date(range[0]);
        const end = new Date(range[1]);

        const getTimeslots = async (start, end, listingId) => {

          const sequence = getDateRanges2(start, end);

          const queries = sequence.map(datesPara => sdk.timeslots.query({ start: datesPara.start, end: datesPara.end, listingId: listingId }));


          const results = await Promise.all(queries);

          const meta = { totalItems: 0, totalPages: 1, page: 1, perPage: 700 };

          const allTimeslots = results.flatMap((timeslotsResponse) => {

            const { data: timeslots, meta: pagination } = timeslotsResponse.data;

            meta.totalItems += pagination.totalItems;


            return timeslots;

          });

          return allTimeslots;

        };

        const bufferQuery = fullData.map(async listing => {

          const { bufferStart, bufferEnd } = getBufferStartEnd({
            start: start,
            end: end,
            listing,
          });

          try {

            const currentDate = new Date();

            if(currentDate > bufferStart.toDate()){
              return null;
            }

            const timeslots = await getTimeslots(bufferStart.toDate(), bufferEnd.toDate(), listing?.id);

            const timeZone = listing?.attributes?.availabilityPlan?.timezone;
            const startdayInListingTZ = getStartOf(bufferStart, 'day', timeZone);
            const enddayInListingTZ = getStartOf(bufferEnd, 'day', timeZone);
  
            const sufficientSeats = getAvailabilityForPeriod({
              bufferStart: startdayInListingTZ,
              bufferEnd: enddayInListingTZ,
              timeslots: timeslots,
              requiredSeats: 1,
            });

            return sufficientSeats ? listing : null;
          } catch (error) {

            return null;
          }



        });

        return Promise.all(bufferQuery).then(resps => {

          const filtered = resps.filter(item => item != null);

          const fullResponse = {
            data: {
              data: filtered,
              meta: {
                ...responses[0].data.meta
              }
            },
          }

          dispatch(searchListingsSuccess(fullResponse));
          return fullResponse;
        });

      } else {


        const fullResponse = {
          data: {
            data: [...fullData],
            meta: {
              ...responses[0].data.meta
            }
          },
        }

        dispatch(searchListingsSuccess(fullResponse));
        return fullResponse;

      }



    })
    .catch(e => {
      const error = storableError(e);
      dispatch(searchListingsError(error));
      if (!(isErrorUserPendingApproval(error) || isForbiddenError(error))) {
        throw e;
      }
    });

};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const searchListingsCall = searchListings(
    {
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.listing': [
        'title',
        'geolocation',
        'price',
        'publicData.listingType',
        'publicData.transactionProcessAlias',
        'publicData.unitType',
        'publicData.dayPriceTiers',
        // These help rendering of 'purchase' listings,
        // when transitioning from search page to listing page
        'publicData.pickupEnabled',
        'publicData.shippingEnabled',
        'publicData.endBuffer',
        'publicData.startBuffer',
        'availabilityPlan',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },
    config
  );
  return dispatch(searchListingsCall);
};
